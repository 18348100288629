<div class="navbar bg-primary shadow-sm" *ngIf="userLogged">

    <div *ngIf="Title; then thenBlock else elseBlock"></div>

    <ng-template #thenBlock>
        <a class="navbar-brand mr-md-auto font-weight-normal text-white" routerLink="/">
            <img src="./assets/images/logo-s2l.png" width="50" height="50"
                class="d-inline-block align-top mr-2 rounded border border-white" alt="" loading="lazy">
            <span class="h1">{{Title}}</span>
        </a>

    </ng-template>

    <ng-template #elseBlock>
        <a class="navbar-brand mr-md-auto font-weight-normal text-white" routerLink="/">
            <img src="./assets/images/logo-s2l.png" width="40" height="40"
                class="d-inline-block align-top mr-2 rounded border border-white" alt="" loading="lazy">
            Send2Look
        </a>
    </ng-template>

    <nav class="nav">
        <ul class="nav justify-content-end">
            <li class="nav-item">
                <a class="nav-link text-white">
                    {{userLogged.userName}} <img src="../assets/images/s2l-profile.png" class="img-profile rounded-circle">
                </a>

            </li>
        </ul>
    </nav>
</div>