import { Component, OnInit } from '@angular/core';
import { PanelService } from '../service/panel.service';
import { Panel } from '../model/panel';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  panels: Panel[];

  constructor(private panelService: PanelService) { }

  ngOnInit(): void {
    this.gelPanels();
  }

  gelPanels() {
    this.panelService.getPanels()
      .subscribe(x => this.panels = x);
  }
}
