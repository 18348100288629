export class UserRegister {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
}

export class UserLogin {
    email: string;
    password: string;    
}

export class UserLogged {
    userId: string;
    userName: string;
    creationDate: string;
    expirationDate: string;
    token: string;
}