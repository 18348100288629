import { Component, OnInit, Input } from '@angular/core';
import { AccountService } from '../service';
import { UserLogged } from '../model';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  @Input() Title: string;
  userLogged: UserLogged;
  
  constructor(private accountService: AccountService) {
    this.accountService.userLogged.subscribe(x => this.userLogged = x);
  }

  ngOnInit(): void {
    
  }

}
