<app-nav [Title]="panel?panel.title:null"></app-nav>

<div class="container-fluid" *ngIf="panel">
    <!-- <div class="mt-3 ml-4">
        <h1 class="text-white">{{panel.title}}</h1>
    </div> -->
    <div *ngIf="!message" class="text-center shadow-sm p-3 main-content m-4">
        <h2 class="display-1 text-center">Você pode enviar mensagens agora. Experimente!</h2>
    </div>
    <div *ngIf="message" class="text-center shadow-sm p-3 main-content m-4">

        <div class="sender-content d-flex justify-content-center">
            <div class="p-2 bd-highlight">
                <img src="../assets/images/s2l-profile.png" class="img-profile rounded-circle">
            </div>
            <div class="p-2 bd-highlight">
                <p class="lead text-center mb-0" [style.color]="message.layout.font.color">
                    {{message.owner.name}}
                </p>
                <p class="text-center" [style.color]="message.layout.font.color">
                    <small>
                        {{calculateDiff(message.date)}}
                    </small>
                </p>
            </div>
        </div>

        <h2 class="display-1" [style.color]="message.layout.font.color">{{message.content}}</h2>



    </div>
</div>

<nav class="fixed-bottom p-1 navbar-light bg-primary">
    <form [formGroup]="form" (ngSubmit)="sendMessage()">
        <div class="input-group">
            <input type="text" class="form-control" placeholder="Enviar uma mensagem..."
                aria-label="Enviar uma mensagem..." aria-describedby="button-addon2" formControlName="content"
                maxlength="140">
            <div class="input-group-append">
                <button class="btn btn-primary text-white border border-white" type="submit" id="button-addon2"><svg
                        class="bi bi-chat-dots-fill" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M16 8c0 3.866-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7zM5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                    </svg></button>
            </div>
        </div>
    </form>
</nav>