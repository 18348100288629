import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { AccountService } from '../service';
import { environment } from 'src/environments/environment';
import { catchError, filter, take, switchMap } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private accountService: AccountService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const user = this.accountService.userValue;
        const isLoggedIn = user && user.token;
        const isApiUrl = request.url.startsWith(environment.apiUrl);

        if (isLoggedIn && isApiUrl) {
            request = this.addToken(request, user.token);
        }

        return next.handle(request).pipe(catchError(error => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
                return this.handle401Error(request, next);
            } else {
                return throwError(error);
            }
        }));
    }

    private addToken(request: HttpRequest<any>, token: string) {
        return request.clone({
            setHeaders: {
                'Authorization': `Bearer ${token}`
            }
        });
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {        
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            return this.accountService.refreshToken().pipe(                
                switchMap((user: any) => {
                    this.isRefreshing = false;
                    this.refreshTokenSubject.next(user);
                    return next.handle(this.addToken(request, user.token));
                }));

        } else { 
            this.accountService.logout();
            // return this.refreshTokenSubject.pipe(
            //     filter(user => user != null),
            //     take(1),
            //     switchMap(token => {
            //         return next.handle(this.addToken(request, token));
            //     }),
            //     catchError(err => {
            //         debugger;
            //         this.accountService.logout();
            //         console.error(err);
            //         return next.handle(null);
            //     }));
        }
    }
}