export class Panel {
    id: string;
    name: string;
    title: string;
    description: string;
    bgImage: string;
    owner: {
        name: string;
    }
}

export class PanelCreate {    
    name: string;
    title: string;
    description: string;    
}