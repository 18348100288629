import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlertService, PanelService } from 'src/app/service';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-create-panel',
  templateUrl: './create-panel.component.html',
  styleUrls: ['./create-panel.component.scss']
})
export class CreatePanelComponent implements OnInit {

  form: FormGroup;
  loading = false;
  submitted = false;

  constructor(private formBuilder: FormBuilder,
    private alertService: AlertService,
    private panelService: PanelService,
    private route: ActivatedRoute,
    private router: Router,) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: ['', Validators.required, Validators.maxLength(15)],
      title: ['', Validators.required, Validators.maxLength(30)],
      description: ['', Validators.required]
    });
  }

  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    this.panelService.createPanel(this.form.value)
      .pipe(first())
      .subscribe(
        data => {
          this.alertService.success('Criado com sucesso', { keepAfterRouteChange: true });
          this.router.navigate(['/'], { relativeTo: this.route });
        },
        error => {
          this.alertService.error(error);
          this.loading = false;
        });
  }

}
