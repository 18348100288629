export class Message {
    id: number;
    content: string;    
    date: string;
    owner: {
        name: string;
    }
    layout: {
        background: {
            color: string;
        },
        font: {
            color: string;
            family: string;
        }        
    }
}

export class GetMessage {
    lastMessage: string;
    messages: Message[];
}

export class SendMenssage {
    content: string;
    panel: {
        id: string;
    }
    layout: {
        id: string;
    }
}