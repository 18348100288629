<!-- <div class="container mt-5">
    <h1>Paineis</h1>
    <div class="row">
        <div class="col" *ngFor="let item of panels">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">{{item.title}}</h5>   
                    <p class="card-text">{{item.description}}</p>
                    <a [routerLink]="['/',item.name]" class="card-link">PARTICIPAR DO PAINEL</a>
                </div>
            </div>
        </div>
    </div>
</div> -->
<app-nav></app-nav>
<div class="container mt-5">
    <div class="card-columns">
        <div class="card" *ngFor="let item of panels" [routerLink]="['/', item.name]">
            <img class="card-img-top" [src]="item.bgImage" alt="Card image cap">
            <div class="card-img-overlay h-78 d-flex align-items-end">
                <h4 class="card-title text-center bg-title">{{item.title}}</h4>
            </div>
            <div class="card-footer bg-transparent">
                {{item.description}}
            </div>
        </div>
    </div>

    <div class="d-flex justify-content-center" *ngIf="!panels">
        <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <!-- <div class="card-columns mt-5">
        <div class="card bg-light" *ngFor="let item of panels">
            <div class="card-body">
                <h5 class="card-title">{{item.title}}</h5>
                <p class="card-text">{{item.description}}</p>
                <small class="text-muted">{{item.name}}</small>
            </div>
            <div class="card-footer text-right">
                <a [routerLink]="['/',item.name]" class="btn btn-primary text-white">Ver</a>
            </div>
        </div>
    </div> -->
</div>