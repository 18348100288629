import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { UserRegister } from '../model';
import { UserLogin } from '../model';
import { UserLogged } from '../model';
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({ providedIn: 'root' })
export class AccountService {
    private userLoggedSubject: BehaviorSubject<UserLogged>;
    public userLogged: Observable<UserLogged>;


    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.userLoggedSubject = new BehaviorSubject<UserLogged>(JSON.parse(localStorage.getItem('user')));
        this.userLogged = this.userLoggedSubject.asObservable();
    }

    public get userValue(): UserLogged {
        return this.userLoggedSubject.value;
    }

    login(email, password) {
        return this.http.post<UserLogged>(`${environment.apiUrl}/user/login`, { email, password })
            .pipe(map(userLogged => {
                userLogged = this.decodeUser(userLogged);
                localStorage.setItem('user', JSON.stringify(userLogged));                
                this.userLoggedSubject.next(userLogged);
                return userLogged;
            }));
    }

    logout() {
        localStorage.removeItem('user');
        this.userLoggedSubject.next(null);
        this.router.navigate(['/account/login']);
    }

    register(userRegister: UserRegister) {
        return this.http.post(`${environment.apiUrl}/user/register`, userRegister);
    }

    refreshToken() {
        return this.http.post<any>(`${environment.apiUrl}/user/refresh-token`, this.userLoggedSubject.value)
            .pipe(
                map(userLogged => {
                    userLogged = this.decodeUser(userLogged);
                    localStorage.setItem('user', JSON.stringify(userLogged));
                    this.userLoggedSubject.next(userLogged);
                    return userLogged;
                }),
                catchError((err) => {
                    this.logout();
                    console.error(err);

                    return throwError(err);
                })
            );
    }

    decodeUser(userLogged): UserLogged { 
        const helper = new JwtHelperService();
        
        let decodeToken = helper.decodeToken(userLogged.token);

        userLogged.userId = decodeToken.unique_name
        userLogged.userName = decodeToken.family_name;

        return userLogged;
    }

    // getAll() {
    //     return this.http.get<User[]>(`${environment.apiUrl}/users`);
    // }

    // getById(id: string) {
    //     return this.http.get<User>(`${environment.apiUrl}/users/${id}`);
    // }

    // update(id, params) {
    //     return this.http.put(`${environment.apiUrl}/users/${id}`, params)
    //         .pipe(map(x => {
    //             // update stored user if the logged in user updated their own record
    //             if (id == this.userValue.Id) {
    //                 // update local storage
    //                 const user = { ...this.userValue, ...params };
    //                 localStorage.setItem('user', JSON.stringify(user));

    //                 // publish updated user to subscribers
    //                 this.userLoggedSubject.next(user);
    //             }
    //             return x;
    //         }));
    // }

    // delete(id: string) {
    //     return this.http.delete(`${environment.apiUrl}/users/${id}`)
    //         .pipe(map(x => {
    //             // auto logout if the logged in user deleted their own record
    //             if (id == this.userValue.Id) {
    //                 this.logout();
    //             }
    //             return x;
    //         }));
    // }
}