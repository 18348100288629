import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Message, SendMenssage, GetMessage } from '../model/message';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  
  constructor(private http: HttpClient) { }

  getMessages(panelId: string, lastMessage: string): Observable<GetMessage> {
    const url = `${environment.apiUrl}/panel/${panelId}/messages/${lastMessage}`;
    return this.http.get<GetMessage>(url);
  }

  sendMessage(message: SendMenssage): Observable<string> {
    return this.http.post<string>(`${environment.apiUrl}/message`, message);
  }
}
