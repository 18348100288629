import { Component, OnInit, AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
import { Panel } from '../model/panel';
import { PanelService } from '../service/panel.service';
import { MessageService } from '../service/message.service';
import { Message, SendMenssage } from '../model/message';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent implements OnInit, AfterViewInit, OnDestroy {

  form: FormGroup;
  panel: Panel;
  messages: Array<Message>;
  message: Message;
  length: number;
  interval: any;
  panelName: string;
  lastMessage: string = "";

  constructor(private panelService: PanelService,
    private elementRef: ElementRef,
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private _Activatedroute: ActivatedRoute) {
    this.panelName = this._Activatedroute.snapshot.paramMap.get("panelName");
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    // this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#ffffff';
    this.elementRef.nativeElement.ownerDocument.body.style.background = 'none';
  }

  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      content: ['', Validators.required]
    });

    this.getPanel();
  }

  getPanel() {
    this.panelService.getPanel(this.panelName)
      .subscribe(x => {
        this.panel = x;
        this.elementRef.nativeElement.ownerDocument.body.style.background = 'url(' + x.bgImage + ') no-repeat center';
        this.getMessages(this.panel.id, true);
      });
  }

  getMessages(panelName: string, start?: boolean) {
    this.messageService.getMessages(panelName, this.lastMessage).subscribe(m => {
      this.messages = m.messages;
      this.lastMessage = m.lastMessage;
      this.length = this.messages.length;

      if (start) {
        this.start();
      }
    });
  }

  start() {
    this.nextMessage();

    this.interval = setInterval(() => {
      this.nextMessage();
    }, 6000);
  }

  sendMessage() {
    if (!this.form.valid)
      return;

    this.messageService.sendMessage({
      content: this.form.value.content,
      layout: {
        id: "9e7985f0-efe3-4dbd-975f-fc26ebd9c28c"
      },
      panel: {
        id: this.panel.id
      }
    }).subscribe(s => {
      this.form.reset();
    });
  }

  nextMessage() {
    if (this.length > 0) {
      this.length--;
      this.message = this.messages[this.length];

      // this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = this.message.layout.background.color;

      if (this.length == 0) {
        this.getMessages(this.panel.id, false);
      }
    } else {
      this.getMessages(this.panel.id, false);
    }
  }

  calculateDiff(sentDate) {
    var date1: any = new Date(sentDate);
    var date2: any = new Date();

    let diffMs = (date2 - date1); // milliseconds
    let diff = Math.floor(diffMs / 86400000); // days

    let term;

    if (diff == 0) { 
      diff = Math.floor((diffMs % 86400000) / 3600000); // hours

      if (diff == 0) {
        diff = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

        if(diff == 0) {
          return "Há poucos segundos"
        } else {
          term = diff > 1 ? "minutos" : "minuto";
        }
        
      } else {
        term = diff > 1 ? "horas" : "hora";
      }
    } else {
      term = diff > 1 ? "dias" : "dia";
    }

    return `Há ${diff} ${term}`;
  }
}
