<!-- <nav class="navbar navbar-light bg-light mb-3" *ngIf="user">
  <a class="navbar-brand" href="#">
    <img src="./assets/images/logo-s2l.png" width="30" height="30" class="d-inline-block align-top mr-2" alt=""
      loading="lazy">Send2Look</a>
  <a class="btn btn-outline" (click)="logout()">Sair</a>
</nav>
<div class="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 border-bottom shadow-sm"> -->

<!-- <div class="navbar bg-primary shadow-sm" *ngIf="userLogged">
  <a class="navbar-brand mr-md-auto font-weight-normal text-white" routerLink="/">
    <img src="./assets/images/logo-s2l.png" width="40" height="40"
      class="d-inline-block align-top mr-2 rounded border border-white" alt="" loading="lazy">Send2Look</a>
      
  <nav class="nav">    
    <ul class="nav justify-content-end">
      <li class="nav-item">
        <a class="nav-link text-white">
          Fulano de Tal <img src="../assets/images/s2l-profile.png" class="img-profile rounded-circle"> 
        </a> -->
        
        <!-- <a class="nav-link text-light" routerLink="/panel/create">Criar Painel</a> -->
      <!-- </li>
    </ul>
  </nav> -->
  <!-- <button class="btn btn-outline-light btn-m" (click)="logout()"><i class="fa fa-power-off"></i></button> -->
<!-- </div> -->

<!-- <app-nav></app-nav> -->
<div class="app-container">
  <alert></alert>
  <router-outlet></router-outlet>
</div>