<div class="container">
    <h2 class="mb-5 mt-5">Criar Painel</h2>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label for="name">Identificador do painel</label>
            <input type="text" formControlName="name" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.name.errors }" placeholder="Ex: AmigosDoTrabalho, AniversarioMaria, ..."/>
            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                <div *ngIf="f.name.errors.required">O nome para identificar o painel é obrigatório</div>
            </div>
        </div>
        <div class="form-group">
            <label for="title">Título</label>
            <input type="text" formControlName="title" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.title.errors }" placeholder="Ex: Amigos do Trabalho, Aniversário da Maria, ..."/>
            <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                <div *ngIf="f.title.errors.required">O título é obrigatório</div>
            </div>
        </div>
        <div class="form-group">
            <label for="description">Descrição</label>
            <textarea formControlName="description" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.description.errors }" rows="3" placeholder="Ex: Vamos comemorar a amizade com esse painel dos amigos..."></textarea>
            <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                <div *ngIf="f.description.errors.required">A descrição é obrigatória</div>
            </div>
        </div>
        <div class="form-group">
            <button [disabled]="loading" class="btn btn-primary">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Criar
            </button>
            <a routerLink="/" class="btn btn-link">Cancelar</a>
        </div>
    </form>
</div>