import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Panel, PanelCreate } from '../model/panel';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PanelService {
 
  constructor(
    private http: HttpClient) { }

  getPanel(name: string): Observable<Panel> {
    const url = `${environment.apiUrl}/panel/panels/${name}`;    
    return this.http.get<Panel>(url);
  }

  getPanels(): Observable<Panel[]> {
    const url = `${environment.apiUrl}/panel/panels`;
    return this.http.get<Panel[]>(url);
  }

  createPanel(panelCreate: PanelCreate) {
    return this.http.post(`${environment.apiUrl}/panel/create`, panelCreate);
  }
}
